import React from "react";

import { FeatureFlagsProvider } from "@common/Providers";

type Props = {
  children: React.ReactNode;
};

// TODO: uncomment QueryClientProvider
export const AppProvidersLayout: React.FC<Props> = ({ children }) => {
  return <FeatureFlagsProvider>{children}</FeatureFlagsProvider>;
};
