import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";

import { useGetPermissions } from "@api/queries";
import { ROLES, ROUTES } from "@common/Constants";
import { useNavigateTo, useSelectedChat } from "@common/Hooks";
import { Queries, RoleType } from "@common/Types";
import { getUserRole } from "@common/Utils";
import { Tabs, Typography } from "@components/Elements";

import { MemberList } from "./components/shared";

const TAB_VALUE = {
  DRIVER: "chat_with_drivers",
  RIDER: "chat_with_riders",
};

const TABS = [
  {
    value: TAB_VALUE.DRIVER,
    label: "Водители",
    content: <MemberList type="drivers" />,
  },
  {
    value: TAB_VALUE.RIDER,
    label: "Пассажиры",
    content: <MemberList type="riders" />,
  },
];

export const ChatShared = () => {
  // states
  const [activeTab, setActiveTab] = useState(0);
  const { setSelectedChat } = useSelectedChat();
  const { navigateTo } = useNavigateTo();

  const queries: Queries = queryString.parse(window.location.search);
  const userRole = getUserRole() as RoleType;

  // if role dispatcher check permissions
  const isDispatcher = [ROLES.MOTORADMIN, ROLES.NAVIADMIN].includes(userRole);

  // hooks
  const { response } = useGetPermissions(isDispatcher);

  useEffect(() => {
    if (queries.riders) {
      setActiveTab(1);
    }

    return () => {
      setSelectedChat(null);
    };
  }, []);

  const handleTab = (active: number) => {
    setActiveTab(active);
    setSelectedChat(null);
    navigateTo(ROUTES.CHAT);
  };

  const tabs = useMemo(() => {
    if (isDispatcher) {
      const activeTabValues = response?.reduce((acc, { name, is_active }) => {
        if (is_active) {
          acc.add(name);
        }

        return acc;
      }, new Set<string>());

      return TABS.filter(({ value }) => activeTabValues?.has(value));
    }

    return TABS;
  }, [isDispatcher, response]);

  return (
    <>
      <Typography
        as="h2"
        variant="heading-1-b"
        color="neutral-800"
        className="font-bold flex justify-between mb-[16px]"
      >
        Чат поддержки
      </Typography>
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={handleTab} />
    </>
  );
};
