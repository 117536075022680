import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { supportChatService } from "@api/services";
import { ChatMessageFilterTypes, ChatMessageTypes } from "@common/Types";

type FilterTypes = ChatMessageFilterTypes & {
  enabled?: boolean;
};

export const useGetDriversMessagesById = (
  id: string,
  filters: FilterTypes = {},
) => {
  const { enabled = true, ...otherFilter } = filters;

  const { data, ...query } = useQuery<ChatMessageTypes>({
    queryKey: [QUERY_KEYS.SUPPORT_DRIVERS_CHAT_MESSAGE_LIST_BY_ID, filters, id],
    queryFn: async () => {
      return await supportChatService.getDriversMessagesById(id, otherFilter);
    },

    enabled: enabled && !!id,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });

  return { response: data, ...query };
};
