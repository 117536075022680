import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { showErrorToast } from "@common/Utils";

// Create an Axios instance with a base URL
export const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Custom error handling function
const handleApiError = (error: unknown): never => {
  if (axios.isAxiosError(error)) {
    const message =
      error.response?.data?.message ||
      "An error occurred while processing your request.";

    showErrorToast(error);
    throw new Error(message);
  } else {
    console.error("Unexpected Error:", error);
    throw new Error("An unexpected error occurred");
  }
};

// Define the API client methods
export const httpClient = {
  get: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await apiClient.get<T>(url, config);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  post: async <T, D>(
    url: string,
    data: D,
    config?: AxiosRequestConfig,
  ): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await apiClient.post<T>(
        url,
        data,
        config,
      );
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  put: async <T, D>(
    url: string,
    data: D,
    config?: AxiosRequestConfig,
  ): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await apiClient.put<T>(
        url,
        data,
        config,
      );
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },

  delete: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await apiClient.delete<T>(url, config);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  },
};
